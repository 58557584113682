var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prizeResources" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "已购买奖品", name: "first" } },
            [
              _c(
                "ul",
                { staticClass: "giftList" },
                _vm._l(_vm.list, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "gift-item",
                      class: { active: index == _vm.current1 },
                      on: {
                        click: function($event) {
                          return _vm.buyPrizeType(index, item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _vm._l(_vm.userList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "prize-item" },
                  [
                    _c("prize-item", {
                      ref: "prizeItem",
                      refInFor: true,
                      attrs: { prizeType: "giftBuy", userItem: item }
                    })
                  ],
                  1
                )
              }),
              _vm.userList.length == 0
                ? _c("p", { staticClass: "noData" }, [_vm._v("暂无数据")])
                : _vm._e(),
              _c("el-pagination", {
                staticClass: "giftPage",
                attrs: {
                  "current-page": _vm.pageIndex1,
                  "page-sizes": [12, 20, 50, 100],
                  "page-size": _vm.pageSize1,
                  total: _vm.totalPage1,
                  layout: "total, sizes, prev, pager, next, jumper"
                },
                on: {
                  "size-change": _vm.sizeChangeHandle1,
                  "current-change": _vm.currentChangeHandle1
                }
              })
            ],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "自建奖品", name: "second" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-bottom": "20px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.addData }
                },
                [_vm._v("新增奖品")]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.dataList }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      "header-align": "center",
                      align: "center",
                      label: "奖品名称"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      label: "奖品图片"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.img
                              ? _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px"
                                  },
                                  attrs: { src: scope.row.img, alt: "" }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      label: "创建时间"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.createTime))])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stock.totalStock",
                      "header-align": "center",
                      align: "center",
                      label: "剩余库存"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stock.lockupStock",
                      "header-align": "center",
                      align: "center",
                      label: "冻结数量"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stock.usableStock",
                      "header-align": "center",
                      align: "center",
                      label: "可用数量",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      label: "状态"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.status == 1
                              ? _c("span", [_vm._v("启用")])
                              : scope.row.status == 0
                              ? _c("span", [_vm._v("禁用")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      "header-align": "center",
                      align: "center",
                      label: "操作"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.getIdData(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            scope.row.status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setStatus(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("启用")]
                                )
                              : scope.row.status == 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setStatus(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("禁用")]
                                )
                              : _vm._e(),
                            scope.row.status == 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      size: "small",
                                      disabled: ""
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.delPrize(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("el-pagination", {
                staticClass: "giftPage",
                staticStyle: { "text-align": "right", "margin-top": "30px" },
                attrs: {
                  "current-page": _vm.pageIndex2,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.pageSize2,
                  total: _vm.totalPage2,
                  layout: "total, sizes, prev, pager, next, jumper"
                },
                on: {
                  "size-change": _vm.sizeChangeHandle2,
                  "current-change": _vm.currentChangeHandle2
                }
              }),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "新增奖品",
                    visible: _vm.isVisible,
                    width: "30%"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.isVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-width": "120px",
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖品名称：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "输入奖品名称" },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "库存：", prop: "stock.usableStock" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "输入奖品数量",
                              oninput: "value=value.replace(/[^\\d]/g,'')",
                              maxlength: "8"
                            },
                            model: {
                              value: _vm.form.stock.usableStock,
                              callback: function($$v) {
                                _vm.$set(_vm.form.stock, "usableStock", $$v)
                              },
                              expression: "form.stock.usableStock"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          ref: "img",
                          attrs: { label: "奖品图片：", prop: "img" }
                        },
                        [
                          _c("upload-com", {
                            ref: "uploadCom",
                            attrs: { showImg: "showImg" },
                            on: { addImg: _vm.addImgEvent }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.isVisible = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submit }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "奖品中心", name: "third" } },
            [
              _c(
                "ul",
                { staticClass: "giftList" },
                [
                  _vm._l(_vm.list, function(item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "gift-item",
                        class: { active: index == _vm.current },
                        on: {
                          click: function($event) {
                            return _vm.prizeCenterType(index, item)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  _c("li", { staticClass: "cart" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cart-content",
                        on: { click: _vm.showCar }
                      },
                      [
                        _c("i", { staticClass: "el-icon-shopping-cart-2" }),
                        _vm._v(" 购物车 ")
                      ]
                    )
                  ])
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "prizeList" },
                _vm._l(_vm.prizeList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "prize-item" },
                    [
                      _c("prize-item", {
                        ref: "prizeItem",
                        refInFor: true,
                        attrs: { item: item, prizeType: "buyGifts" }
                      })
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.prizeList.length == 0
                ? _c("p", { staticClass: "noData" }, [_vm._v("暂无数据")])
                : _vm._e(),
              _c("el-pagination", {
                staticClass: "giftPage",
                attrs: {
                  "current-page": _vm.pageIndex3,
                  "page-sizes": [12, 20, 50, 100],
                  "page-size": _vm.pageSize3,
                  total: _vm.totalPage3,
                  layout: "total, sizes, prev, pager, next, jumper"
                },
                on: {
                  "size-change": _vm.sizeChangeHandle3,
                  "current-change": _vm.currentChangeHandle3
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("cart", { on: { currentChangeHandle3: _vm.currentChangeHandle3 } }),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.carVisible, width: "60%" },
          on: {
            "update:visible": function($event) {
              _vm.carVisible = $event
            }
          }
        },
        [
          _vm.carVisible
            ? _c("shopping-cart", { ref: "shoppingCart" })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }