<template>
  <!-- 奖品资源 -->
  <div class="prizeResources">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="已购买奖品" name="first">
        <ul class="giftList">
          <li v-for="(item, index) in list" :key="index" @click="buyPrizeType(index, item)" :class="{'active': index == current1}" class="gift-item">{{ item.name }}</li>
        </ul>
        <div v-for="(item, index) in userList" :key="index" class="prize-item">
          <prize-item ref="prizeItem" prizeType="giftBuy" :userItem="item"></prize-item>
        </div>
        <p v-if="userList.length == 0" class="noData">暂无数据</p>
        <el-pagination
          class="giftPage"
          @size-change="sizeChangeHandle1"
          @current-change="currentChangeHandle1"
          :current-page="pageIndex1"
          :page-sizes="[12, 20, 50, 100]"
          :page-size="pageSize1"
          :total="totalPage1"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="自建奖品" name="second">
        <el-button type="primary" style="float: right; margin-bottom: 20px" @click="addData">新增奖品</el-button>
        <el-table
          :data="dataList"
          style="width: 100%;">
        <el-table-column
          prop="name"
          header-align="center"
          align="center"
          label="奖品名称">
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="奖品图片">
          <template slot-scope="scope">
            <img :src="scope.row.img" alt="" v-if="scope.row.img" style="width:50px; height: 50px">
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="创建时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="stock.totalStock"
          header-align="center"
          align="center"
          label="剩余库存">
        </el-table-column>
        <el-table-column
          prop="stock.lockupStock"
          header-align="center"
          align="center"
          label="冻结数量">
        </el-table-column>
        <el-table-column
          prop="stock.usableStock"
          header-align="center"
          align="center"
          label="可用数量"
          width="120">
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">启用</span>
            <span v-else-if="scope.row.status == 0">禁用</span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="getIdData(scope.row.id)">编辑</el-button>
            <el-button type="text" size="small" v-if="scope.row.status == 0" @click="setStatus(scope.row)">启用</el-button>
            <el-button type="text" size="small" v-else-if="scope.row.status == 1" @click="setStatus(scope.row)">禁用</el-button>
            <el-button type="text" size="small" v-if="scope.row.status == 1" disabled>删除</el-button>
            <el-button type="text" size="small" @click="delPrize(scope.row)" v-else>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="giftPage"
        @size-change="sizeChangeHandle2"
        @current-change="currentChangeHandle2"
        :current-page="pageIndex2"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize2"
        :total="totalPage2"
        layout="total, sizes, prev, pager, next, jumper"
        style="text-align: right; margin-top: 30px">
      </el-pagination>
      <!-- 新增奖品 -->
      <el-dialog title="新增奖品" :visible.sync="isVisible" width="30%">
        <el-form :model="form" ref="form" label-width="120px" :rules="rules">
          <el-form-item label="奖品名称：" prop="name">
            <el-input placeholder="输入奖品名称" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="库存：" prop="stock.usableStock">
            <el-input placeholder="输入奖品数量" v-model="form.stock.usableStock" oninput="value=value.replace(/[^\d]/g,'')" maxlength="8"></el-input>
          </el-form-item>
          <el-form-item label="奖品图片：" prop="img" ref="img">
            <upload-com @addImg="addImgEvent"  showImg="showImg" ref="uploadCom"></upload-com>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button @click="isVisible = false">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="奖品中心" name="third">
          <ul class="giftList">
            <li v-for="(item, index) in list" :key="index"  @click="prizeCenterType(index, item)" :class="{'active': index == current}" class="gift-item">{{ item.name }}</li>
            <li class="cart">
              <div class="cart-content" @click="showCar">
                <i class="el-icon-shopping-cart-2"></i>
                购物车
              </div>
            </li>
          </ul>
          <div class="prizeList">
            <div v-for="(item, index) in prizeList" :key="index" class="prize-item">
              <prize-item ref="prizeItem" :item="item" prizeType="buyGifts"></prize-item>
            </div>
          </div>
          <p v-if="prizeList.length == 0" class="noData">暂无数据</p>
            <el-pagination
              class="giftPage"
              @size-change="sizeChangeHandle3"
              @current-change="currentChangeHandle3"
              :current-page="pageIndex3"
              :page-sizes="[12, 20, 50, 100]"
              :page-size="pageSize3"
              :total="totalPage3"
              layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <!-- 添加购物车 -->
    <cart @currentChangeHandle3="currentChangeHandle3"></cart>
    <!-- 购物车 -->
    <el-dialog :visible.sync="carVisible" width="60%">
      <shopping-cart ref="shoppingCart" v-if="carVisible"></shopping-cart>
    </el-dialog>
  </div>
</template>

<script>
import PrizeItem from '@/components/PrizeItem.vue'
import { getSelfPrize, addSelfPrize, getIdSelfPrize, updateSelfPrize, getResourcesType, getGiftData, getPrizeList, delSelfPrize, setSelfDisable, setSelfEnable } from '@/api/market'
import UploadCom from '@/components/upload/uploadCom.vue'
import { addImg } from '@/api/myDesign'
import cart from '@/components/cart.vue'
import ShoppingCart from '../../MarketingActivityEdit/ShoppingCart.vue'

export default {
  components: {
    PrizeItem,
    UploadCom,
    cart,
    ShoppingCart
  },
  data() {
    return {
      carVisible: false,
      activeName: 'first',
      current: 0,
      current1: 0,
      list: [],
      pageIndex3: 1,
      pageSize3: 12,
      totalPage3: 0,
      isVisible: false,
      pageIndex2: 1,
      pageSize2: 10,
      totalPage2: 0,
      pageIndex1: 1,
      pageSize1: 12,
      totalPage1: 0,
      userList: [],
      prizeList: [],
      dataList: [],
      taxonomyId1: '',
      taxonomyId: '',
      rules: {},
      id: '',
      form: {
        img: '',
        name: '',
        stock: {
          usableStock: ''
        }
      },
      rules: {
        name: [
          { required: true, message: '请输入奖品名称', trigger: 'blur' }
        ],
        'stock.usableStock': [
          { required: true, message: '请输入库存数量', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ],
      }
    }
  },
  created () {
    this.getData()
    this.getType()
  },
  beforeDestroy () {
    this.$bus.$off('setImgUrl')
  },
  watch: {
    isVisible(newVal) {
      if(!newVal) {
        this.form = {
          img: '',
          name: '',
          stock: {
            usableStock: ''
          }
        }
        this.$bus.$emit('setImgUrl')
        this.$refs.form.resetFields()
      }
    }
  },
  methods: {
    // 购物车
    showCar () {
      this.carVisible = true
      setTimeout(() => {
        this.$refs.shoppingCart.pageIndex = 1
        this.$refs.shoppingCart.getCarList()
      }, 0)
    },
    handleClick(tab, event) {
      if(this.activeName == 'first') {
        this.pageIndex1 = 1
        this.getType()
      }else if(this.activeName == 'third') {
        this.pageIndex3 = 1
        this.getDataList(0, this.taxonomyId)
      }
    },
    // ----------------------------------------已购买奖品------------------------------------------  
    // 获取礼品分类
    async getType () {
      const res = await getResourcesType({pageNo: 1, pageSize: 100000000})
      if(res.code == 200) {
        this.list = res.data.list
        this.getDataList(0, res.data.list[0].id)
        this.getGiftUserList(0, res.data.list[0].id)
      }
    },
    buyPrizeType (index, item) {
      this.pageIndex1 = 1
      this.getGiftUserList(index, item.id)
    },
    // 获取礼品账户列表
    async getGiftUserList (index, taxonomyId) {
      this.current1 = index
      this.taxonomyId1 = taxonomyId
      const res = await getPrizeList({pageNo: this.pageIndex1, pageSize: this.pageSize1, classificationId: taxonomyId})
      if(res.code == 200) {
        this.userList = res.data.list
        this.totalPage1 = res.data.total
      }
    },
    // 每页数
    sizeChangeHandle1 (val) {
      this.pageSize1 = val
      this.pageIndex1 = 1
      this.getGiftUserList(this.current1, this.taxonomyId1)
    },
    // 当前页
    currentChangeHandle1 (val) {
      this.pageIndex1 = val
      this.getGiftUserList(this.current1, this.taxonomyId1)
    }, 
    // ----------------------------------------自建奖品------------------------------------------
    // 获取奖品数据
    async getData () {
      const res = await getSelfPrize({pageNo: this.pageIndex2, pageSize: this.pageSize2})
      if(res.code == 200) {
        this.dataList = res.data.list
        this.totalPage2 = res.data.total
      }
    },
    // 每页数
    sizeChangeHandle2 (val) {
      this.pageSize2 = val
      this.pageIndex2 = 1
      this.getData()
    },
    // 当前页
    currentChangeHandle2 (val) {
      this.pageIndex2 = val
      this.getData()
    },
    // 文件上传
    addImgEvent (data) {
      this.form.img = data.fileUrl
      this.$refs.img.clearValidate()
    },
    // 根据id查询
    async getIdData (id) {
      this.isVisible = true
      const res = await getIdSelfPrize({id})
      if(res.code == 200) {
        this.id = id
        this.form = res.data
        this.$refs.uploadCom.url = res.data.img
        this.form.stock.stockChange = this.form.stock.usableStock
      }
    },
    // 新增
    addData () {
      this.id = ''
      this.isVisible = true
    },
    // 提交
    async submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res
          if(this.id) {
            this.form.stock.stockChange = this.form.stock.usableStock - this.form.stock.stockChange
            res = await updateSelfPrize({id: this.id, ...this.form})
          }else {
            res = await addSelfPrize(this.form)
          }
          if(res.code == 200) {
            this.$message.success('保存成功')
            this.isVisible = false
            this.pageIndex2 = 1
            this.getData()
          }
        }
      })
    },
    // 修改状态
    async setStatus (data) {
      let res
      if(data.status == 1) {
         res = await setSelfDisable({id: data.id})
      }else {
        res = await setSelfEnable({id: data.id})
      }
      if(res.code == 200) {
        this.$message.success('状态修改成功')
        this.getData()
      }
    },
    // 删除
    async delPrize (data) {
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async () => {
        const res = await delSelfPrize({id: data.id})
        if (res.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              if(this.totalPage2 % this.pageSize2 == 1) {
                this.pageIndex2--
              }
              this.getData()
            }
          })
        }else {
          this.$message(res.msg)
        }
      }).catch(() => {})
    },
    // ----------------------------------------奖品中心------------------------------------------
    // 获取分类礼品数据
    async getDataList(index, taxonomyId) {
      this.current = index
      this.taxonomyId = taxonomyId
      const res = await getGiftData({pageNo: this.pageIndex3, pageSize: this.pageSize3, classificationId: taxonomyId})
      if(res.code == 200) {
        this.prizeList = res.data.list
        this.totalPage3 = res.data.total
      }
    },
    // 每页数
    sizeChangeHandle3 (val) {
      this.pageSize3 = val
      this.pageIndex3 = 1
      this.getDataList(this.current, this.taxonomyId)
    },
    // 当前页
    currentChangeHandle3 (val) {
      this.pageIndex3 = val
      this.getDataList(this.current, this.taxonomyId)
    },
    prizeCenterType (index, item) {
      this.pageIndex3 = 1
      this.getDataList(index, item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .prizeResources {
    padding: 30px;
    /deep/ .el-tabs__item {
      padding: 0 40px;
    }
    .noData {
      text-align: center;
      margin: 20px 0;
      color: #999;
    }
    .prize-item {
      display: inline-block;
      margin: 0 30px 30px 0;
    }
    .giftList {
      display: flex; 
      margin: 30px 0;
      .gift-item {
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        text-align: center;
        background-color: #eee;
        margin-right: 10px;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          background-color: #666;
        }
      }
      .cart {
        flex: 1;
        display: flex;
        height: 40px;
        line-height: 40px;
        justify-content: flex-end;
        .cart-content {
          width: 70px;
          text-align: center;
          margin-right: 125px;
          cursor: pointer;
        }
      }
      .active {
        color: #fff;
        background-color: #666;
      }
    }
    .giftPage {
      text-align: right;
    }
  }
</style>